import React from 'react';
import { Link } from 'react-router-dom';

const PortfolioItemLinkBox = ({project}) => {

  const {category, name, id} = project;
  return (
    <Link to={ `/projects/${id}` }>
      <div className='portfolio__tabs__content__box__text d-flex justify-content-between align-items-center'>
        <div>
          <h4>{ name }</h4>
          <button className='button button--primary'>{ category }</button>
        </div>
        <div>
          <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-5.24032e-07 9.38966L-3.92898e-07 12.3356L18.3146 12.3356L11.3126 19.1453L13.4097 21.1621L24 10.8626L13.4097 0.540489L11.3126 2.60265L18.3146 9.38966L-5.24032e-07 9.38966Z" fill="#fff" />
          </svg>
          {/* <img src={ arrow } className='w-100' alt='arrow'/> */ }
        </div>
      </div>
    </Link>
  )
}

export default PortfolioItemLinkBox
