import React from 'react';
import logo from '../assets/images/logo.svg';
import close from '../assets/images/icons/navigation/close.svg';
import { Link } from 'react-router-dom';
import NavbarMenuLinks from './NavbarMenuLinks';
import NavbarMenuInfo from './NavbarMenuInfo';

const NavbarMenu = () => {  

  return (
    <div className='offcanvas offcanvas-end offcanvas-menu' id='navbarMenu' tabIndex="-1" aria-labelledby="offcanvasNavbarLabel">
      <div className='offcanvas__wrapper container'>
        <div className='offcanvas__header row'>
          <div className='col-5 col-md-7 offcanvas__header__logo'>
            <Link to='/' data-bs-dismiss="offcanvas" aria-label="Close">
              <img src={ logo } alt='Softelm Logo' />
            </Link>
          </div>
          <div className='col-7 col-md-5 d-flex aligin-items-center justify-content-end offcanvas__header__buttons'>
            {/* <button className='button button--secondary' type='button'>Start a project</button> */}
            <button className='button button--secondary' type='button' data-bs-toggle="offcanvas" data-bs-target="#offcanvas-contact" aria-controls="offcanvas-contact">Start a project</button>
            <button type="button" className="button__close" data-bs-dismiss="offcanvas" aria-label="Close">
              <img className='navbar-toggler-icon-close' src={ close } alt='Close Icon'></img>
            </button>
          </div>
        </div>
        <div className='row h-100 offcanvas__body'>
          <NavbarMenuInfo />
          <NavbarMenuLinks />
        </div>
      </div>
    </div>
  )
}

export default NavbarMenu;