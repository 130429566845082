import React from 'react';
import TestimonialsSlider from './TestimonialsSlider';

function Testimonials() {
  return (
    <div className='container'>
      <p className='caption col-12'>Testimonial</p>
      <div className='row testimonials__about-section'>
        <h2 className='col-12 col-lg-5'>You're in<br />good company</h2>
        <p className='col-12 offset-md-1 col-md-6'>Consistency is the true foundation of trust.<br />We keep your promises that’s why our clients loves us.</p>
      </div>
      <div className='row'>
        <div className='col-12'>
          <TestimonialsSlider />
        </div>
      </div>
    </div>
  )
}

export default Testimonials;
