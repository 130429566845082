import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import TeamSliderItem from './TeamSliderItem';

function TeamSlider() {

  const [team, setTeam] = useState([]);

  useEffect(()=>{
    fetch('../../data.json')
      .then(response => response.json())
      .then(data => setTeam(data.team))
  }, []);

  const NextArrow = (props) => {
    const { style, onClick } = props;
    return (
      <div className='next-arrow' style={ { ...style, display: 'block' } } onClick={ onClick } />
    )
  }
  const PrevArrow = (props) => {
    const { style, onClick } = props;
    return (
      <div className='prev-arrow' style={ { ...style, display: 'block' } } onClick={ onClick } />
    )
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
  };

  return (
    <div className='team__slider'>
      <Slider { ...settings }>
        {
          team.length > 0 && team.map((member, index) => {
            return (
              <TeamSliderItem member={ member } key={ index } />
            )
          })
        }
      </Slider>
    </div>
  )
}

export default TeamSlider;
