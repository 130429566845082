import React, { useState, useEffect } from 'react'
import Slider from "react-slick";
import TestimonialsSliderItem from './TestimonialsSliderItem';


function TestimonialsSlider() {

  const [testimonials, setTestimonials] = useState([]);
  
  useEffect(()=>{
    fetch('../../data.json')
      .then(response => response.json())
      .then(data => setTestimonials(data.testimonials))
  }, []);

  const NextArrow = (props) => {
    const { style, onClick } = props;
    return (
      <div className='next-arrow' style={ { ...style, display: 'block' } } onClick={ onClick } />
    )
  }
  const PrevArrow = (props) => {
    const { style, onClick } = props;
    return (
      <div className='prev-arrow' style={ { ...style, display: 'block' } } onClick={ onClick } />
    )
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1
        }
      }
    ],
  };

  return (
    <div id='testimonials-slider'>
      <Slider { ...settings }>
        { testimonials.length > 0 && testimonials.map((t, i) => {
          return (
            <TestimonialsSliderItem testimonial={t} key={i}/>
          )
        }) }
      </Slider>
    </div>
  )
}

export default TestimonialsSlider;
