import React from 'react';
import * as dayjs from 'dayjs';

function TeamProgressBar({ startingDate }) {

  let startingJobDate = dayjs(startingDate);
  let currentDate = dayjs();

  let differenceInDates = currentDate.diff(startingJobDate, 'month', true);
  let differenceInMonths = currentDate.diff(startingJobDate, 'month');
  let workingDaysInMonth = (differenceInDates % 1).toFixed(2) * 100;

  return (
    <>
      <div className='progress'>
        <div className='progress-bar progress-bar-striped progress-bar-animated'
        aria-valuenow={workingDaysInMonth} aria-valuemin="0" aria-valuemax="100"
        style={ { width: `${workingDaysInMonth}%`, backgroundColor: '#FFD500' } }>
          {/* <span style={{width: '20px', height: '30px', borderRadius: '50%', backgroundColor: 'red'}}></span> */}
        </div>
      </div>
      <div className='mt-2'>
        <h4>Level { differenceInMonths }</h4>
      </div>
    </>
  )
}

export default TeamProgressBar;
