import React, { useEffect } from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import ProjectViewItem from './ProjectViewItem';


function ProjectPage() {
  useEffect(() => window.scrollTo(0, 0), [])
  return (
    <>
      <Navbar />
      <main>
        <section className='project-info'>
          <ProjectViewItem />
        </section>
      </main>
      <footer className='footer'>
        <Footer />
      </footer>
    </>
  )
}

export default ProjectPage;
