import React from 'react';
import badge from '../assets/images/badge.png';

function Hero() {
  return (
    <div className='hero'>
      <div className='container'>
        <div className='row h-100'>
          <div className='col-12 col-lg-8 hero__text'>
            <p className='caption'><span className='hero__text--white'>welcome to</span> softelm</p>
            <h1 className='hero__text__title'>We bring ideas<br />to digital life<span className='hero__text--yellow'>.</span></h1>
            <h4>We are professional, enthusiastic development team, <br />primary oriented to Web Technologies.</h4>
          </div>
          <div className='hero__badge col-12 col-lg-4'>
            <img src={ badge } alt=''/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
