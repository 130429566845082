import React from 'react'

function NavbarMenuInfo() {
  return (
    <div className='col-12 col-md-7 offcanvas__body__contact order-2 order-md-1'>
      <p className='d-flex caption'>Get in touch with us</p>
      <h1>We are excited to hear about <br className='d-none d-md-block' />the project you have planned.</h1>
      <h3><a href='mailto:office@softelm.com'>office@softelm.com</a></h3>
      <h3><a href='tel:+381607638356'>+381 60 76 38 356</a></h3>
      <h3>Vojvode Mišića 60/51, 18000 Niš</h3>
    </div>
  )
}

export default NavbarMenuInfo;
