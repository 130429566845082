import React, { useState, useEffect } from 'react';
import PortfolioItem from './PortfolioItem';

const Portfolio = () => {

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetch('../../data.json')
      .then(response => response.json())
      .then(data => setProjects(data.projects))
  }, [])

  return (
    <div className='container'>
      <div className='row'>
        <p className='caption col-12'>Highlights of our work</p>
        <div className='row portfolio__about-section'>
          <h2 className='col-12 col-md-5'>We've created some cool stuff, check it out!</h2>
          <p className='col-12 offset-md-1 col-md-6'>We have worked on various projects, from some small personal apps, open source apps to the top level enterprise applications and systems. You can take a look at some of them bellow</p>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 portfolio__tabs'>
          <nav>
            <div className="nav nav-tabs portfolio__tabs__nav" id="portfolio__tabs__nav" role="tablist">
              <button className="portfolio__tabs__tab caption nav-link active portfolio-tab" id="portfolio-all-tab" data-bs-toggle="tab" data-bs-target="#portfolio-all-box" type="button" role="tab" aria-controls="portfolio-all-box" aria-selected="true">All</button>
              <button className="portfolio__tabs__tab caption nav-link portfolio-tab" id="portfolio-web-tab" data-bs-toggle="tab" data-bs-target="#portfolio-web-box" type="button" role="tab" aria-controls="portfolio-web-box" aria-selected="false">Web Design</button>
              <button className="portfolio__tabs__tab caption nav-link portfolio-tab" id="portfolio-ecom-tab" data-bs-toggle="tab" data-bs-target="#portfolio-ecom-box" type="button" role="tab" aria-controls="#portfolio-ecom-box" aria-selected="false">Ecommerce</button>
              <button className="portfolio__tabs__tab caption nav-link portfolio-tab" id="portfolio-mobi-tab" data-bs-toggle="tab" data-bs-target="#portfolio-mobi-box" type="button" role="tab" aria-controls="#portfolio-mobi-box" aria-selected="false">Mobile apps</button>
            </div>
          </nav>
        </div>
        <div className="tab-content portfolio__tabs__content" id="nav-tabContent">
          <div className="tab-pane fade show active" id="portfolio-all-box" role="tabpanel" aria-labelledby="portfolio-all-tab">
            <div className='row'>
              {
                projects.length > 0 &&
                projects.map(project => {
                  return (
                    <PortfolioItem project={ project } key={ project.id } />
                  )
                }) }
            </div>
          </div>
          <div className="tab-pane fade" id="portfolio-web-box" role="tabpanel" aria-labelledby="portfolio-web-tab">
            <div className='row'>
              { 
                projects.length > 0 &&
                projects.filter(project => project.category_id === 1).map(project => {
                  return (
                    <PortfolioItem project={ project } key={ project.id } />
                  )
                }) }
            </div>
          </div>
          <div className="tab-pane fade" id="portfolio-ecom-box" role="tabpanel" aria-labelledby="portfolio-ecom-tab">
            <div className='row'>
              { 
                projects.length > 0 &&
                projects.filter(project => project.category_id === 2).map(project => {
                  return (
                    <PortfolioItem project={ project } key={ project.id } />
                  )
                }) }
            </div>
          </div>
          <div className="tab-pane fade" id="portfolio-mobi-box" role="tabpanel" aria-labelledby="portfolio-mobi-tab">
            <div className='row'>
              { 
                projects.length > 0 &&
                projects.filter(project => project.category_id === 3).map(project => {
                  return (
                    <PortfolioItem project={ project } key={ project.id } />
                  )
                }) }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Portfolio;